<!-- 动态广告编辑视频 -->
<template>
  <div>
    <el-button
      type="text"
      class="delete"
      icon="el-icon-circle-close"
      @click="deleSource"
    ></el-button>
    <!-- sourceMsg{{ sourceMsg }} -->
    <div
      class="imgCon"
      v-if="!sourceMsg.url"
    >
      <el-button
        size="small"
        @click="imgListShow = true"
        >从创意云选择视频</el-button
      >
      <!-- <el-upload
        class="upload-demo"
        action="#"
        :http-request="upload_img"
        :show-file-list="false"
        :before-upload="beforeUpload"
      >
        <el-button
          size="small"
          type="primary"
          @click="uploadType('video')"
          >点击上传视频</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
          style="line-height: 28px"
        >
          <p>推荐长宽比：1:1</p>
          <p>推荐尺寸：1080 * 1080像素</p>
          <p>最小尺寸：600 * 600像素</p>
          <p>图中文本内容不超过20%效果更佳</p>
        </div>
      </el-upload> -->
      <gm-upload-material
        ref="uploadMaterial"
        v-model:fileList="fileList"
        :saveAdFileParams="saveAdFileParam"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
      >
        <el-button
          size="small"
          type="primary"
          >点击上传视频</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
        >
          <img-tips></img-tips>
        </div>
      </gm-upload-material>
    </div>
    <div
      class="imgCon"
      v-else
      v-loading="sourceMsg.videoStatus !== 'ready'"
    >
      <div class="tools">
        <el-button
          size="mini"
          class="uploadThumb"
          @click="uploadThumbShow = true"
          v-if="sourceMsg.videoStatus == 'ready'"
          >上传/选择封面</el-button
        >
        <i class="el-icon-zoom-in"></i>
        <i
          class="el-icon-delete"
          @click="deleImg"
        ></i>
        <!-- <el-upload
          class="upload-demo"
          action="#"
          :http-request="upload_img"
          :show-file-list="false"
          style="display: inline-block"
          :before-upload="beforeUpload"
        >
          <i
            class="el-icon-upload2"
            @click="uploadType('img')"
          ></i>
        </el-upload> -->
        <gm-upload-material
          class="inline-block"
          ref="uploadMaterial"
          v-model:fileList="fileList"
          :saveAdFileParams="saveAdFileParam"
          :on-success="onSuccess"
          :before-upload="beforeUpload"
        >
          <i
            class="el-icon-upload2"
            @click="uploadType('img')"
          ></i>
        </gm-upload-material>
      </div>
      <div class="imgTips">
        <p>推荐长宽比：1:1</p>
        <p>推荐尺寸：1080 * 1080像素</p>
        <p>最小尺寸：600 * 600像素</p>
        <p>图中文本内容不超过20%效果更佳</p>
      </div>
      <video
        controls
        :src="sourceMsg.url"
        :poster="sourceMsg.thumbUrl"
        v-if="fileType == 'mp4'"
      ></video>
      <el-image
        :src="sourceMsg.url"
        :preview-src-list="[sourceMsg.url]"
        v-if="fileType == 'gif'"
      ></el-image>
    </div>
    <img-list
      :imgListShow="imgListShow"
      :limit="1"
      :saveAdFileParam="saveAdFileParam"
      @close="close"
      @urlList="urlList"
      :uploadTypes="uploadTypes"
    ></img-list>
    <upload-video-thumb
      :uploadThumbShow.sync="uploadThumbShow"
      :sourceMsg="sourceMsg"
      @setThumb="setThumb"
    ></upload-video-thumb>
  </div>
</template>
<script>
import imgTips from '@/views/adManagement/createAd/components/adsComponents/imgTips.vue';
import imgList from '../imgList';
import uploadVideoThumb from './uploadVideoThumb';
import adsMethods from '../../adsMethods.js';
import { saveVideoThumb } from '@/api/creatAd.js';
export default {
  mixins: [adsMethods],
  components: {
    imgList,
    uploadVideoThumb,
    imgTips,
  },
  props: ['uploadTypes', 'index', 'source', 'edit','value'],
  data() {
    return {
      sourceMsg: {
        url: '',
        source_id: null,
        thumbUrl: '',
        thumbList: [],
        cancleVideoUpload: false,
        type: 'video',
        videoStatus: this.source.videoStatus || 'ready',
      },
      fileType: 'mp4',
      imgListShow: false,
      timer: null,
      uploadThumbShow: false,
      sourceType: 'video', // 上传类型
    };
  },
  methods: {
    //关闭创意云
    close(val) {
      this[val] = false;
    },
    deleImg() {
      this.sourceMsg.url = '';
      this.timer = null;
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
    },
    uploadType(val) {
      this.sourceType = val;
    },
    // 获取素材
    urlList(v) {
      let img = v[v.length - 1];
      this.sourceMsg.url = img.url;
      this.sourceMsg.source_id = img.id;
      this.sourceMsg.thumbUrl = img.thumbUrl;
      this.$set(this.sourceMsg, 'sourceType', img.sourceType);
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
      this.uploadVideoFb(img.id, img.sourceType);
    },
    // 上传类型
    // upload_img(file) {
    //   // if (this.sourceType === 'video') {
    //   //   this.ossUpload(file.file)
    //   // } else {
    //   this.uploadImg(file).then((data) => {
    //     this.sourceMsg.url = data.url;
    //     this.sourceMsg.source_id = data.id;
    //     this.sourceMsg.thumbUrl = data.thumbUrl;
    //     this.$emit('setSource', this.index, this.sourceMsg);
    //     this.$emit('emitCreative');
    //     this.uploadVideoFb(data.id);
    //   });
    //   // }
    // },
    onSuccess(response) {
      console.log(response, 'response');
      this.sourceMsg.url = response.url;
      this.sourceMsg.source_id = response.id;
      this.sourceMsg.thumbUrl = response.thumbUrl;
      this.$set(this.sourceMsg, 'sourceType', response.sourceType);
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
      this.uploadVideoFb(response.id, response.sourceType);
    },
    setThumb(v, thumbList) {
      this.sourceMsg.thumbUrl = v;
      this.sourceMsg.thumbList = thumbList;
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
    },
    setThumbList(list, sourceType) {
      console.log('aaaaa', list, sourceType);
      this.thumbList = list;
      let uri = list.find((v) => v.isPreferred).uri;
      const params = {
        sourceType:this.sourceMsg.sourceType,
        videoFileId: this.sourceMsg.source_id,
        videoThumbUrl: uri,
      };
      this.uploadThumb(params);
    },
    uploadThumb(formData) {
      const params = {
        ...formData,
        uid: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        accountId: this.$store.getters.accountId,
      };
      console.log(params, 'params');
      
      this.$showLoading();
      saveVideoThumb(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '上传成功',
          });
          this.setThumb(res.data.thumbUrl, this.thumbList);
        }
      }).finally(() => {
        this.$hideLoading();
      })
    },
    deleSource() {
      this.timer = null;
      this.sourceMsg.cancleVideoUpload = true;
      this.$emit('deleSource', this.index);
    },
  },
  watch: {
    source: {
      handler() {
        this.sourceMsg = {
          ...this.sourceMsg,
          ...this.source,
          videoStatus: this.edit ? this.sourceMsg.videoStatus || 'ready' : this.sourceMsg.videoStatus || this.source.videoStatus,
          thumbList:
            this.sourceMsg.thumbList && this.sourceMsg.thumbList.length
              ? this.sourceMsg.thumbList
              : [{ uri: this.sourceMsg.thumbUrl, isPreferred: true }],
        };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.thumbList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .thumbWrap {
    width: 120px;
    height: 120px;
    margin: 8px;
    cursor: pointer;
    position: relative;
    i {
      position: absolute;
      top: -5px;
      right: -5px;
      background: #333;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.delete {
  position: absolute;
  top: -20px;
  right: -5px;
}
.uploadThumb {
  position: absolute;
  top: 100px;
  left: -100px;
  //     transform: translate(-50%,-50%);
}
</style>
